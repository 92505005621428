import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IMessageModel } from './types'
import { fetchCustomers, sendMessage, getFromChannel } from './services'
import { loadingStatusType } from 'commons/types/load'

interface IInitialState {
  data: IMessageModel[]
  loading: loadingStatusType
  page: number
  size: number
  count: number;
}

const initialState: IInitialState = {
  data: [],
  loading: 'idle',
  count: 0,
  page: 0,
  size: 0
}

export const messages = createSlice({
  name: 'message',
  initialState,
  reducers: {
    resetMessages: (state) => {
      state.data = []
    },
    addMessages: (state, { payload }: PayloadAction<IMessageModel>) => {
      if (
        state.data?.length &&
        state.data[0]?.channelId === payload.channelId
      ) {
        let msgs = state.data
        msgs.push(payload)
        state.data = msgs
      }
    },
    setMessages: (state, { payload }: PayloadAction<IMessageModel[]>) => {
      state.data = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = 'success'
        state.data = action.payload
      })
      .addCase(fetchCustomers.rejected, (state) => {
        state.loading = 'error'
      })
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = 'loading'
      })
      .addCase(getFromChannel.fulfilled, (state, action) => {
        state.loading = 'success'
        state.data = [...state.data, ...action.payload.data];
      })
      .addCase(getFromChannel.rejected, (state) => {
        state.loading = 'error'
      })
      .addCase(getFromChannel.pending, (state) => {
        state.loading = 'loading'
      })
      .addCase(sendMessage.fulfilled, (state) => {
        state.loading = 'success'
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = 'error'
      })
      .addCase(sendMessage.pending, (state) => {
        state.loading = 'loading'
      })
  },
})

export const { setMessages, addMessages, resetMessages } = messages.actions

export default messages.reducer
