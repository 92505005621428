import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IChannelModel, IChannelResponse } from './types'
import {
  fetchChannels,
  searchChannel,
  setAssignUserChannel,
  updateChannelStatus,
} from './services'
import { loadingStatusType } from 'commons/types/load'
import { RootState } from 'store'
import { IMessageModel } from 'models/messages/types'

interface IInitialState {
  data: IChannelModel[]
  selectedChannel: IChannelModel
  status: loadingStatusType,
}

const initialState: IInitialState = {
  data: null,
  selectedChannel: null,
  status: 'idle',
}

export const channels = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    resetChannels: (state) => {
      state.data = null
      state.selectedChannel = null
    },
    selectChannel: (state, { payload }: PayloadAction<IChannelModel>) => {
      state.selectedChannel = payload
    },
    updateChannelsData: (state, { payload }: PayloadAction<IMessageModel>) => {
      const newChannels = state.data?.map((channel: IChannelModel) => {
        if (channel.id === payload.channelId) {
          const msgs = channel.messages
          msgs.push(payload)

          channel.messages = msgs
          channel.updated_at = payload.updated_at
        }
        return channel
      })

      state.data = newChannels
    },
    addNewChannel: (state, { payload }: PayloadAction<IChannelModel>) => {
      let channels = [payload]
      if (state?.data?.length) channels = [...channels, ...state.data]
      state.data = channels
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChannels.fulfilled, (state, action) => {
        state.data = action.payload.data

        state.status = 'idle'
      })
      .addCase(fetchChannels.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(fetchChannels.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(setAssignUserChannel.fulfilled, (state, action) => {
        state.selectedChannel.userId = action.payload.data.userId
        const newData = state.data.map((item) => {
          if (item.id === action.payload.data.id) {
            item.userId = action.payload.data.userId
            return item
          }
          return item
        })
        state.data = newData
        state.status = 'idle'
      })
      .addCase(setAssignUserChannel.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(setAssignUserChannel.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateChannelStatus.fulfilled, (state, action) => {
        state.selectedChannel = action.payload.data
        state.status = 'idle'
      })
      .addCase(updateChannelStatus.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(updateChannelStatus.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(
        searchChannel.fulfilled,
        (state, { payload }: PayloadAction<IChannelResponse>) => {
          state.data = payload.data
          state.status = 'idle'
        }
      )
      .addCase(searchChannel.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(searchChannel.pending, (state) => {
        state.status = 'loading'
      })
  },
})

export const {
  selectChannel,
  updateChannelsData,
  resetChannels,
  addNewChannel,
} = channels.actions

export const channelsSelector = (state: RootState) => state.channels

export default channels.reducer
