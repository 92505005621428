import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'services/client'
import {
  IGetChannelMessagesRequest,
  IGetMessagesFromChannelResponse,
  IMessageModel,
  IUserSendMessage,
} from './types'

export const fetchCustomers = createAsyncThunk<IMessageModel[], undefined>(
  'customers/getAll',
  async (_, thunkAPI) => {
    const response = await api.get<IMessageModel[]>(
      'cx-messages-service/api/tenantid/getAll'
    )
    return response.data
  }
)

export const sendMessage = createAsyncThunk<string, IUserSendMessage>(
  'message/sendMessage',
  async (data, thunkApi) => {
    const response = await api.post<string>(
      'cx-messages-service/api/channels/userSendMessage',
      data
    )
    return response.data
  }
)

export const getFromChannel = createAsyncThunk<
  IGetMessagesFromChannelResponse,
  IGetChannelMessagesRequest
>('channels/getMessages', async ({ id, page, size = 150 }, _thunkAPI) => {
  const response = await api.get<IGetMessagesFromChannelResponse>(
    `cx-messages-service/api/channels/${id}/messages`,
    {
      params: {
        sortOrder: 'DESC',
        withPagination: 'true',
        sortParam: 'created_at',
        channelId: id,
        size,
        page,
      },
    }
  )

  const newData = response.data.data.sort(
    (a: IMessageModel, b: IMessageModel) => {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    }
  )

  return {
    ...response.data,
    data: newData,
  }
})
