import scrollBar from './common/scrollBar'
import { createGlobalStyle, DefaultTheme } from 'styled-components'

const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family:${({ theme }: any) => theme.typography.baseTypography.fontFamily};
}

*::before,
*::after {
  box-sizing: border-box;
}

${scrollBar}
`

export default GlobalStyle as any;