import React, { useEffect } from 'react'
import * as S from './ChannelsList.styles'
import ChannelCard from 'pages/Main/parts/ChannelsList/parts/ChannelCard'
import { Input } from 'Components/Inputs'
import FilterTags from './parts/FilterTags'

import { useChannel } from 'models/channel/hooks'
import { Loading } from 'Components/Loading'
import { useMessage } from 'models/messages/hooks'
import { useAppSelector } from 'store'
import { IChannelModel } from 'models/channel/types'
import { useChat } from 'models/chatClients/hooks'

const ChannelsList = () => {
  const { data: userData } = useAppSelector((state) => state.user)

  const { status, data, handleSetSelected, getChannels, filter } = useChannel({
    initialValue: 'ALL',
  })

  const { handleSetMessages, handleGetMessages } = useMessage()
  const { handleAssignUser, handleSearch } = useChat()

  useEffect(() => {
    const bootstrap = () => {
      getChannels(filter)
    }

    return () => {
      bootstrap()
    }
  }, [filter])

  const renderItems = React.useCallback(() => {
    if (status === 'loading') return <Loading size={50} />

    const newData = [...(data || [])].sort(
      (a: IChannelModel, b: IChannelModel) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    )
    const arr = []

    newData?.forEach((item: IChannelModel) => {
      if (
        (item?.channelStatus !== 4 && item?.userId === userData?.id) ||
        !item?.userId
      )
        arr.push(
          <ChannelCard
            key={item.id}
            data={item}
            onClick={() => {
              if (!item.userId) {
                handleAssignUser(userData?.id, item.id, true)
              }
              handleSetMessages([])
              handleSetSelected(item)
              handleGetMessages({ id: item.id })
            }}
          />
        )
    })

    return arr;
  }, [
    data,
    handleSetSelected,
    handleSetMessages,
    status,
    userData?.id,
    handleAssignUser,
  ])

  return (
    <S.Container>
      <FilterTags initialValue="ALL" />
      <Input
        name="input_search_filter"
        iconLeft={{
          name: 'ph-magnifying-glass',
        }}
        type="text"
        onChange={handleSearch}
      />
      <S.ChannelCardListContainer>{renderItems()}</S.ChannelCardListContainer>
    </S.Container>
  )
}

export default ChannelsList
