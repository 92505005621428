import { useCallback, useState, useEffect } from 'react'
import {
  closeChannelStatus,
  searchChannel,
  setAssignUserChannel,
  updateChannelStatus,
} from '../channel/services'
import { useAppDispatch, useAppSelector } from '../../store'
import { fetchCustomers } from './services'
import { CHANNEL_STATUS } from '../channel/enums'

export const useChat = () => {
  const dispatch = useAppDispatch()
  const { data: userData } = useAppSelector((state) => state.user)
  const channels = useAppSelector((state) => state.channels)

  const [userAssigned, setAssignUser] = useState<string>(null)
  const [statusChannelStatus, setChannelStatus] = useState<string>(null)
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    if (channels?.selectedChannel) {
      switch (channels.selectedChannel.channelStatus) {
        case 0:
          return setChannelStatus('CREATED')
        case 1:
          return setChannelStatus('PENDING')
        case 2:
          return setChannelStatus('OPEN')
        case 3:
          return setChannelStatus('FREEZE')
        case 4:
          return setChannelStatus('CLOSED')
        default:
          return setChannelStatus(null)
      }
    } else {
      setChannelStatus(null)
    }
  }, [channels?.selectedChannel])

  useEffect(() => {
    if (channels?.selectedChannel) {
      setAssignUser(channels?.selectedChannel?.userId)
    } else {
      setAssignUser(null)
    }
  }, [channels?.selectedChannel])

  const getClients = useCallback(() => {
    dispatch(fetchCustomers())
  }, [dispatch])

  const handleAssignUser = useCallback(
    (
      userId: string | null,
      channelId?: string | null,
      firstInteraction?: boolean
    ) => {
      setAssignUser(userId)
      dispatch(
        setAssignUserChannel({
          id: channelId ?? channels.selectedChannel?.id,
        })
      )
    },
    [dispatch, channels.selectedChannel?.id]
  )

  const handleSearch = useCallback(
    (text: string) => {
      dispatch(searchChannel({ id: userData?.id, channelName: text }))
    },
    [dispatch, userData?.id]
  )

  const handleSetChannelStatus = useCallback(
    (status: string | null) => {
      const channelStatus = CHANNEL_STATUS[status]

      setChannelStatus(status)

      if (channelStatus !== CHANNEL_STATUS.CLOSED)
        return dispatch(
          updateChannelStatus({
            id: channels.selectedChannel?.id,
            channelStatus,
          })
        )
    },
    [dispatch, channels.selectedChannel?.id]
  )

  const handleConfirCloseChannel = useCallback(() => {
    dispatch(closeChannelStatus({ id: channels.selectedChannel?.id }))
    setShowModal(false)
  }, [dispatch, channels.selectedChannel?.id])

  const handleCloseModal = useCallback(() => {
    setShowModal(false)
  }, [])

  return {
    ...channels,
    getClients,
    userAssigned,
    handleAssignUser,
    handleSetChannelStatus,
    statusChannelStatus,
    handleSearch,
    showModal,
    handleConfirCloseChannel,
    handleCloseModal,
  }
}
